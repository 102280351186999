.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.6rem;
  position: absolute;
  width: 100%;
  background: transparent;
  transform: translate(-50%, -50%);
  top: 96%;
  left: 50%;
}
.footer-container {
  max-width: 1140px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.footer-container p {
  font-size: 1rem;
}
.social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
@media screen and (max-width: 640px) {
  .footer-container p {
    font-size: 0.7rem;
  }
}
