.swiper {
  max-width: 90%;
  height: 90%;
}

.swiper-slide {
  background: transparent;
}

.swiper-slide img {
  width: 100%;
  height: 100vh;
  object-fit: contain;
}
.mySwiper {
  --swiper-navigation-color: #fff;
  --swiper-pagination-color: #fff;
  --swiper-navigation-size: 1.4rem;
}
