.about {
  width: 100%;
  margin: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  position: relative;
  flex-direction: column;
}
#service {
  background-color: rgba(0, 0, 0, 0.488);
  border-radius: 5px;
}

.about .right {
  max-width: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 2rem;
}

.right .img {
  max-width: 450px;
  width: 100%;
  border-radius: 5px;
}
.right-info {
  max-width: 450px;
  width: 100%;
}
.right-info h1 {
  text-align: center;
  font-size: 1.6rem;
  padding: 1rem;
}
.right-info p {
  text-align: justify;
  font-size: 1.2rem;
  padding: 0.2rem;
}

@media screen and (min-width: 2000px) {
  .about .left p {
    font-size: 1.2rem;
  }
  .about .right {
    max-width: 900px;
  }
}
@media screen and (max-width: 600px) {
  .right-info h1 {
    text-align: center;
    font-size: 1.2rem;
    padding: 0.5rem;
  }
  .right-info p {
    text-align: justify;
    font-size: 0.9rem;
    padding: 0.2rem;
  }
}
