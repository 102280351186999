.hero-img-contact {
  display: flex;
  flex-direction: column;
  height: 50vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.3)),
    url("../assets/MasterBedroom/MasterBedroom6.jpg");
  background-size: cover;
  background-position: center bottom;
  padding: 10px;
  gap: 5px;
}

#contact {
  background-color: rgba(0, 0, 0, 0.333);
}
