.footer2 {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  padding: 1rem;
  position: relative;
  width: 100%;
  background: transparent;
}
.footer2-container {
  max-width: 1140px;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.footer2-container p {
  font-size: 1rem;
}
.social {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
}
@media screen and (max-width: 640px) {
  .footer2-container p {
    font-size: 0.7rem;
  }
}
