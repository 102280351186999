.content {
  position: relative;
  transform: translate(-50%, -50%);
  top: 40%;
  left: 50%;
  text-align: center;
}
.content h1 {
  font-size: 2.3rem;
  padding: 0.6rem 0 1.5rem;
}
.content p {
  font-size: 1.4rem;
  padding: 0.6rem 0 2.5rem 1.6rem;
}
.content .btn {
  margin: 1rem 0.2rem;
}
@media screen and (max-width: 1080px) {
  .content h1 {
    font-size: 1.5rem;
  }
  .content p {
    font-size: 1rem;
    padding: 0.6rem 0 1.5rem;
  }
}
@media screen and (max-width: 678px) {
  .content .btn {
    margin: 0.6rem 0.2rem;
    font-size: 0.8rem;
  }
}
