.main-slider {
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 1.6rem;
  position: relative;
  flex-wrap: wrap;
}
.front-img {
  max-width: 1200px;
  height: 550px;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
}
@media screen and (max-width: 2500px) {
  .front-img {
    max-width: 900px;
    height: 350px;
    width: 100%;
  }
}

@media screen and (max-width: 2000px) {
  .front-img {
    max-width: 700px;
    height: 350px;
    width: 100%;
  }
}
@media screen and (max-width: 1536px) {
  .front-img {
    max-width: 600px;
    height: 350px;
    width: 100%;
  }
}
@media screen and (max-width: 1280px) {
  .front-img {
    max-width: 500px;
    height: 300px;
    width: 100%;
  }
}
@media screen and (max-width: 1080px) {
  .main-slider {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 1rem;
    position: relative;
    flex-wrap: wrap;
  }

  .front-img {
    max-width: 800px;
    height: 300px;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .front-img {
    max-width: 700px;
    height: 300px;
    width: 100%;
  }
}
@media screen and (max-width: 640px) {
  .front-img {
    max-width: 600px;
    height: 300px;
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .front-img {
    max-width: 400px;
    height: 250px;
    width: 100%;
  }
}
@media only screen and (max-width: 318px) {
  .front-img {
    max-width: 250px;
    height: 125px;
    width: 100%;
  }
}
