.hero-img {
  width: 100%;
  height: 90vh;
  position: relative;
}
.hero-img::before {
  content: "";
  background: url("../assets/MasterBedroom/MasterBedroom6.jpg");
  background-size: cover;
  background-position: center bottom;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.heading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.hero-img h1 {
  font-size: 2.3rem;
  text-align: center;
  font-weight: 400;
}
.hero-img p {
  font-size: 1.4rem;
  text-align: center;
}
@media screen and (max-width: 1080px) {
  .hero-img h1 {
    font-size: 1.5rem;
    margin-top: 1rem;
  }
  .hero-img p {
    font-size: 1rem;
  }
}
