@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: 0;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
  /* font-display: swap; */
}
body {
  background: #18191b;
}
h1,
h4,
p,
a {
  color: #fefefe;
  text-decoration: none;
}
ul {
  list-style-type: none;
}
.btn {
  background: transparent;
  color: #fefefe;
  padding: 12px 32px;
  font-size: 1rem;
  text-transform: uppercase;
  border: 1px solid #fefefe;
  cursor: pointer;
  font-weight: 400;
  border-radius: 3px;
}
.btn:hover {
  background: rgba(255, 255, 255, 0.2);
  color: #fefefe;
  transition: 0.3s;
}
