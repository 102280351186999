.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: fixed;
  width: 100%;
  /* height: 150px; */
  border-bottom: 0;
  z-index: 10;
  background: transparent;
}
.nav-menu {
  display: flex;
  padding: 1rem;
}
.nav-menu li {
  padding: 0 1rem;
}
.nav-menu li a {
  font-size: 1.2rem;
  font-weight: 300;
}
.hamburger {
  display: none;
}
.logo {
  width: 9.375em;
}
@media screen and (max-width: 2000px) {
  .logo {
    width: 8.375em;
  }
  .header {
    padding: 1rem;
  }
  .header li a {
    font-size: 1rem;
  }
}
@media screen and (min-width: 2000px) {
  .logo {
    width: 12.5em;
  }
  .header {
    padding: 1rem;
  }
  .header li a {
    font-size: 1.4rem;
  }
}
@media screen and (max-width: 1080px) {
  .logo {
    width: 6em;
  }
}
@media screen and (max-width: 1040px) {
  .nav-menu {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
    top: 0;
    left: -100%;
    z-index: -3;
    transition: 0.5s;
  }
  .nav-menu.active {
    left: 0;
  }
  .nav-menu li {
    padding: 1rem 0;
  }
  .nav-menu li a {
    font-size: 1.4rem;
  }
  .hamburger {
    display: initial;
  }
}
